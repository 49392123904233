import React from 'react';
import { navigate } from 'gatsby';
import { updateUserCredentials } from '../services/auth';
import { Helmet } from 'react-helmet';
export default class ResetPassword extends React.Component {
  state = {
    newPassword: ``,
    isLoading: false,
    error: ``
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    updateUserCredentials({ password: this.state.newPassword })
      .then(response => {
        console.log(response);
        this.setState({ error: `` });
        navigate('/app/profile');
      })
      .catch(err => {
        this.setState({ error: err.message });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div className="flex justify-center items-center" style={{ height: '100vh', width: '100vw' }}>
        <Helmet title="Changer le mot de passe" />
        <div
          className="bg-grey-light px-10 py-10 flex flex-col justify-center items-center"
          style={{ maxWidth: '800px' }}
        >
          <h1 className="uppercase">Connexion</h1>
          <p>
            Un mail de réinitialisation de mot de passe vous a guidé jusqu'ici. Veuillez en saisir un nouveau en le
            saisissant ci-dessous.
          </p>
          <strong>Ne rafraichissez pas la page ou vous devrez recommencer!</strong>
          <hr />
          <p className="text-red">{this.state.error}</p>
          <form
            id="loginForm"
            method="post"
            onSubmit={event => {
              this.handleSubmit(event);
            }}
          >
            <div className="mt-3 flex items-center">
              <label className="font-bold text-right pr-5" style={{ width: '250px' }}>
                Nouveau mot de passe
              </label>
              <input
                type="password"
                name="newPassword"
                onChange={this.handleUpdate}
                className="border px-2"
                style={{ width: '400px', height: '50px' }}
                value={this.state.newPassword}
              />
            </div>
          </form>
          <button
            form="loginForm"
            disabled={this.state.isLoading || !this.state.newPassword.length}
            type="submit"
            className="bg-grey px-10 py-2 hover:bg-grey-dark mt-2 uppercase"
          >
            {this.state.isLoading ? 'Patientez...' : `Enregistrer`}
          </button>
        </div>
      </div>
    );
  }
}
