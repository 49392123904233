import GoTrue from 'gotrue-js';
import { navigate } from 'gatsby';
const auth = new GoTrue({
  APIUrl: `https://${process.env.NETLIFY_DOMAIN_NAME}/.netlify/identity`,
  audience: '',
  setCookie: false
});

const isBrowser = () => typeof window !== 'undefined';

export const signup = ({ username, password, data }) => {
  return auth.signup(username, password, data);
};

export const login = ({ username, password }) => {
  return auth.login(username, password, true);
};

export const requestPasswordRecovery = email => {
  return auth.requestPasswordRecovery(email);
};

export const recoverUser = token => {
  return auth.recover(token);
};

export const getCurrentUser = () => {
  return isBrowser() ? auth.currentUser() : null;
};

export const updateUserCredentials = ({ email, password }) => {
  const user = getCurrentUser();

  if (!isLoggedIn()) {
    return new Promise((resolve, reject) => reject('No user is currently logged in.'));
  }

  return user.update({ email, password });
};

export const confirmEmailChangeByToken = token => {
  const user = getCurrentUser();
  return user.update({ email_change_token: token });
};

export const updateUserData = ({
  firstname,
  lastname,
  phone,
  street,
  streetBis,
  city,
  zipCode,
  shippingInstructions,
  useShippingAddressForBilling,
  billingFullName,
  billingStreet,
  billingStreetBis,
  billingCity,
  billingZipCode
}) => {
  const user = getCurrentUser();

  return user.update({
    data: {
      firstname,
      lastname,
      phone,
      street,
      streetBis,
      city,
      zipCode,
      shippingInstructions,
      useShippingAddressForBilling,
      billingFullName,
      billingStreet,
      billingStreetBis,
      billingCity,
      billingZipCode
    }
  });
};

export const getJwtToken = () => {
  const user = getCurrentUser();
  const jwt = user.jwt();
  jwt
    .then(response => console.log('This is a JWT token', response))
    .catch(error => {
      console.log('Error fetching JWT token', error);
      throw error;
    });
};

export const logout = () => {
  const user = getCurrentUser();
  user
    .logout()
    .then(response => {
      navigate('/');
    })
    .catch(error => {
      console.log('Failed to logout user: %o', error);
    });
};

export const isLoggedIn = () => {
  const user = getCurrentUser();

  return !!user;
};
